// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Shape } from '../../onnxruntime/fbs/shape.js';
import { TensorDataType } from '../../onnxruntime/fbs/tensor-data-type.js';

export class TensorTypeAndShape {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): TensorTypeAndShape {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsTensorTypeAndShape(bb: flatbuffers.ByteBuffer, obj?: TensorTypeAndShape): TensorTypeAndShape {
    return (obj || new TensorTypeAndShape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsTensorTypeAndShape(
    bb: flatbuffers.ByteBuffer,
    obj?: TensorTypeAndShape,
  ): TensorTypeAndShape {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new TensorTypeAndShape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  elemType(): TensorDataType {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : TensorDataType.UNDEFINED;
  }

  shape(obj?: Shape): Shape | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? (obj || new Shape()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
  }

  static startTensorTypeAndShape(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addElemType(builder: flatbuffers.Builder, elemType: TensorDataType) {
    builder.addFieldInt32(0, elemType, TensorDataType.UNDEFINED);
  }

  static addShape(builder: flatbuffers.Builder, shapeOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, shapeOffset, 0);
  }

  static endTensorTypeAndShape(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }
}
