// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { DeprecatedKernelCreateInfos } from '../../onnxruntime/fbs/deprecated-kernel-create-infos.js';
import { DeprecatedSubGraphSessionState } from '../../onnxruntime/fbs/deprecated-sub-graph-session-state.js';

/**
 * deprecated: no longer using kernel def hashes
 */
export class DeprecatedSessionState {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): DeprecatedSessionState {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsDeprecatedSessionState(
    bb: flatbuffers.ByteBuffer,
    obj?: DeprecatedSessionState,
  ): DeprecatedSessionState {
    return (obj || new DeprecatedSessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsDeprecatedSessionState(
    bb: flatbuffers.ByteBuffer,
    obj?: DeprecatedSessionState,
  ): DeprecatedSessionState {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new DeprecatedSessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  kernels(obj?: DeprecatedKernelCreateInfos): DeprecatedKernelCreateInfos | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new DeprecatedKernelCreateInfos()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!)
      : null;
  }

  subGraphSessionStates(index: number, obj?: DeprecatedSubGraphSessionState): DeprecatedSubGraphSessionState | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? (obj || new DeprecatedSubGraphSessionState()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  subGraphSessionStatesLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startDeprecatedSessionState(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addKernels(builder: flatbuffers.Builder, kernelsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, kernelsOffset, 0);
  }

  static addSubGraphSessionStates(builder: flatbuffers.Builder, subGraphSessionStatesOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, subGraphSessionStatesOffset, 0);
  }

  static createSubGraphSessionStatesVector(
    builder: flatbuffers.Builder,
    data: flatbuffers.Offset[],
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startSubGraphSessionStatesVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endDeprecatedSessionState(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createDeprecatedSessionState(
    builder: flatbuffers.Builder,
    kernelsOffset: flatbuffers.Offset,
    subGraphSessionStatesOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    DeprecatedSessionState.startDeprecatedSessionState(builder);
    DeprecatedSessionState.addKernels(builder, kernelsOffset);
    DeprecatedSessionState.addSubGraphSessionStates(builder, subGraphSessionStatesOffset);
    return DeprecatedSessionState.endDeprecatedSessionState(builder);
  }
}
