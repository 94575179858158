// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Node } from '../../onnxruntime/fbs/node.js';
import { NodeEdge } from '../../onnxruntime/fbs/node-edge.js';
import { RuntimeOptimizations } from '../../onnxruntime/fbs/runtime-optimizations.js';
import { SparseTensor } from '../../onnxruntime/fbs/sparse-tensor.js';
import { Tensor } from '../../onnxruntime/fbs/tensor.js';
import { ValueInfo } from '../../onnxruntime/fbs/value-info.js';

export class Graph {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): Graph {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsGraph(bb: flatbuffers.ByteBuffer, obj?: Graph): Graph {
    return (obj || new Graph()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsGraph(bb: flatbuffers.ByteBuffer, obj?: Graph): Graph {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new Graph()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  initializers(index: number, obj?: Tensor): Tensor | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new Tensor()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!)
      : null;
  }

  initializersLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  nodeArgs(index: number, obj?: ValueInfo): ValueInfo | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? (obj || new ValueInfo()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  nodeArgsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  nodes(index: number, obj?: Node): Node | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset
      ? (obj || new Node()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!)
      : null;
  }

  nodesLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  maxNodeIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  nodeEdges(index: number, obj?: NodeEdge): NodeEdge | null {
    const offset = this.bb!.__offset(this.bb_pos, 12);
    return offset
      ? (obj || new NodeEdge()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  nodeEdgesLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 12);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  inputs(index: number): string;
  inputs(index: number, optionalEncoding: flatbuffers.Encoding): string | Uint8Array;
  inputs(index: number, optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 14);
    return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
  }

  inputsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 14);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  outputs(index: number): string;
  outputs(index: number, optionalEncoding: flatbuffers.Encoding): string | Uint8Array;
  outputs(index: number, optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 16);
    return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
  }

  outputsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 16);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  sparseInitializers(index: number, obj?: SparseTensor): SparseTensor | null {
    const offset = this.bb!.__offset(this.bb_pos, 18);
    return offset
      ? (obj || new SparseTensor()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  sparseInitializersLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 18);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  runtimeOptimizations(obj?: RuntimeOptimizations): RuntimeOptimizations | null {
    const offset = this.bb!.__offset(this.bb_pos, 20);
    return offset
      ? (obj || new RuntimeOptimizations()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!)
      : null;
  }

  static startGraph(builder: flatbuffers.Builder) {
    builder.startObject(9);
  }

  static addInitializers(builder: flatbuffers.Builder, initializersOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, initializersOffset, 0);
  }

  static createInitializersVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startInitializersVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addNodeArgs(builder: flatbuffers.Builder, nodeArgsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, nodeArgsOffset, 0);
  }

  static createNodeArgsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startNodeArgsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addNodes(builder: flatbuffers.Builder, nodesOffset: flatbuffers.Offset) {
    builder.addFieldOffset(2, nodesOffset, 0);
  }

  static createNodesVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startNodesVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addMaxNodeIndex(builder: flatbuffers.Builder, maxNodeIndex: number) {
    builder.addFieldInt32(3, maxNodeIndex, 0);
  }

  static addNodeEdges(builder: flatbuffers.Builder, nodeEdgesOffset: flatbuffers.Offset) {
    builder.addFieldOffset(4, nodeEdgesOffset, 0);
  }

  static createNodeEdgesVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startNodeEdgesVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addInputs(builder: flatbuffers.Builder, inputsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(5, inputsOffset, 0);
  }

  static createInputsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startInputsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addOutputs(builder: flatbuffers.Builder, outputsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(6, outputsOffset, 0);
  }

  static createOutputsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startOutputsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addSparseInitializers(builder: flatbuffers.Builder, sparseInitializersOffset: flatbuffers.Offset) {
    builder.addFieldOffset(7, sparseInitializersOffset, 0);
  }

  static createSparseInitializersVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startSparseInitializersVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addRuntimeOptimizations(builder: flatbuffers.Builder, runtimeOptimizationsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(8, runtimeOptimizationsOffset, 0);
  }

  static endGraph(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }
}
