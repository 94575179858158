// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { TypeInfo } from '../../onnxruntime/fbs/type-info.js';

export class SequenceType {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): SequenceType {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsSequenceType(bb: flatbuffers.ByteBuffer, obj?: SequenceType): SequenceType {
    return (obj || new SequenceType()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsSequenceType(bb: flatbuffers.ByteBuffer, obj?: SequenceType): SequenceType {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new SequenceType()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  elemType(obj?: TypeInfo): TypeInfo | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? (obj || new TypeInfo()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
  }

  static startSequenceType(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addElemType(builder: flatbuffers.Builder, elemTypeOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, elemTypeOffset, 0);
  }

  static endSequenceType(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createSequenceType(builder: flatbuffers.Builder, elemTypeOffset: flatbuffers.Offset): flatbuffers.Offset {
    SequenceType.startSequenceType(builder);
    SequenceType.addElemType(builder, elemTypeOffset);
    return SequenceType.endSequenceType(builder);
  }
}
