// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { AttributeType } from '../../onnxruntime/fbs/attribute-type.js';
import { Graph } from '../../onnxruntime/fbs/graph.js';
import { Tensor } from '../../onnxruntime/fbs/tensor.js';

export class Attribute {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): Attribute {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsAttribute(bb: flatbuffers.ByteBuffer, obj?: Attribute): Attribute {
    return (obj || new Attribute()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsAttribute(bb: flatbuffers.ByteBuffer, obj?: Attribute): Attribute {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new Attribute()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  name(): string | null;
  name(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  name(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  docString(): string | null;
  docString(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  docString(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  type(): AttributeType {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : AttributeType.UNDEFINED;
  }

  f(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
  }

  i(): bigint {
    const offset = this.bb!.__offset(this.bb_pos, 12);
    return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
  }

  s(): string | null;
  s(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  s(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 14);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  t(obj?: Tensor): Tensor | null {
    const offset = this.bb!.__offset(this.bb_pos, 16);
    return offset ? (obj || new Tensor()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
  }

  g(obj?: Graph): Graph | null {
    const offset = this.bb!.__offset(this.bb_pos, 18);
    return offset ? (obj || new Graph()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
  }

  floats(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 20);
    return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
  }

  floatsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 20);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  floatsArray(): Float32Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 20);
    return offset
      ? new Float32Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  ints(index: number): bigint | null {
    const offset = this.bb!.__offset(this.bb_pos, 22);
    return offset ? this.bb!.readInt64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : BigInt(0);
  }

  intsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 22);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  strings(index: number): string;
  strings(index: number, optionalEncoding: flatbuffers.Encoding): string | Uint8Array;
  strings(index: number, optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 24);
    return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
  }

  stringsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 24);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  tensors(index: number, obj?: Tensor): Tensor | null {
    const offset = this.bb!.__offset(this.bb_pos, 26);
    return offset
      ? (obj || new Tensor()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!)
      : null;
  }

  tensorsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 26);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  graphs(index: number, obj?: Graph): Graph | null {
    const offset = this.bb!.__offset(this.bb_pos, 28);
    return offset
      ? (obj || new Graph()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!)
      : null;
  }

  graphsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 28);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startAttribute(builder: flatbuffers.Builder) {
    builder.startObject(13);
  }

  static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, nameOffset, 0);
  }

  static addDocString(builder: flatbuffers.Builder, docStringOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, docStringOffset, 0);
  }

  static addType(builder: flatbuffers.Builder, type: AttributeType) {
    builder.addFieldInt32(2, type, AttributeType.UNDEFINED);
  }

  static addF(builder: flatbuffers.Builder, f: number) {
    builder.addFieldFloat32(3, f, 0.0);
  }

  static addI(builder: flatbuffers.Builder, i: bigint) {
    builder.addFieldInt64(4, i, BigInt('0'));
  }

  static addS(builder: flatbuffers.Builder, sOffset: flatbuffers.Offset) {
    builder.addFieldOffset(5, sOffset, 0);
  }

  static addT(builder: flatbuffers.Builder, tOffset: flatbuffers.Offset) {
    builder.addFieldOffset(6, tOffset, 0);
  }

  static addG(builder: flatbuffers.Builder, gOffset: flatbuffers.Offset) {
    builder.addFieldOffset(7, gOffset, 0);
  }

  static addFloats(builder: flatbuffers.Builder, floatsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(8, floatsOffset, 0);
  }

  static createFloatsVector(builder: flatbuffers.Builder, data: number[] | Float32Array): flatbuffers.Offset;
  /**
   * @deprecated This Uint8Array overload will be removed in the future.
   */
  static createFloatsVector(builder: flatbuffers.Builder, data: number[] | Uint8Array): flatbuffers.Offset;
  static createFloatsVector(
    builder: flatbuffers.Builder,
    data: number[] | Float32Array | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addFloat32(data[i]!);
    }
    return builder.endVector();
  }

  static startFloatsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addInts(builder: flatbuffers.Builder, intsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(9, intsOffset, 0);
  }

  static createIntsVector(builder: flatbuffers.Builder, data: bigint[]): flatbuffers.Offset {
    builder.startVector(8, data.length, 8);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt64(data[i]!);
    }
    return builder.endVector();
  }

  static startIntsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(8, numElems, 8);
  }

  static addStrings(builder: flatbuffers.Builder, stringsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(10, stringsOffset, 0);
  }

  static createStringsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startStringsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addTensors(builder: flatbuffers.Builder, tensorsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(11, tensorsOffset, 0);
  }

  static createTensorsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startTensorsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addGraphs(builder: flatbuffers.Builder, graphsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(12, graphsOffset, 0);
  }

  static createGraphsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startGraphsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endAttribute(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }
}
