// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { NodesToOptimizeIndices } from '../../onnxruntime/fbs/nodes-to-optimize-indices.js';

/**
 * a single runtime optimization
 * see corresponding type in onnxruntime/core/graph/runtime_optimization_record.h
 */
export class RuntimeOptimizationRecord {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): RuntimeOptimizationRecord {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsRuntimeOptimizationRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: RuntimeOptimizationRecord,
  ): RuntimeOptimizationRecord {
    return (obj || new RuntimeOptimizationRecord()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsRuntimeOptimizationRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: RuntimeOptimizationRecord,
  ): RuntimeOptimizationRecord {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new RuntimeOptimizationRecord()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  actionId(): string | null;
  actionId(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  actionId(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  nodesToOptimizeIndices(obj?: NodesToOptimizeIndices): NodesToOptimizeIndices | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? (obj || new NodesToOptimizeIndices()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!)
      : null;
  }

  producedOpIds(index: number): string;
  producedOpIds(index: number, optionalEncoding: flatbuffers.Encoding): string | Uint8Array;
  producedOpIds(index: number, optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
  }

  producedOpIdsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startRuntimeOptimizationRecord(builder: flatbuffers.Builder) {
    builder.startObject(4);
  }

  static addActionId(builder: flatbuffers.Builder, actionIdOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, actionIdOffset, 0);
  }

  static addNodesToOptimizeIndices(builder: flatbuffers.Builder, nodesToOptimizeIndicesOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, nodesToOptimizeIndicesOffset, 0);
  }

  static addProducedOpIds(builder: flatbuffers.Builder, producedOpIdsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(3, producedOpIdsOffset, 0);
  }

  static createProducedOpIdsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startProducedOpIdsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endRuntimeOptimizationRecord(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }
}
