// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class EdgeEnd {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): EdgeEnd {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  nodeIndex(): number {
    return this.bb!.readUint32(this.bb_pos);
  }

  srcArgIndex(): number {
    return this.bb!.readInt32(this.bb_pos + 4);
  }

  dstArgIndex(): number {
    return this.bb!.readInt32(this.bb_pos + 8);
  }

  static sizeOf(): number {
    return 12;
  }

  static createEdgeEnd(
    builder: flatbuffers.Builder,
    node_index: number,
    src_arg_index: number,
    dst_arg_index: number,
  ): flatbuffers.Offset {
    builder.prep(4, 12);
    builder.writeInt32(dst_arg_index);
    builder.writeInt32(src_arg_index);
    builder.writeInt32(node_index);
    return builder.offset();
  }
}
