// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { DimensionValueType } from '../../onnxruntime/fbs/dimension-value-type.js';

export class DimensionValue {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): DimensionValue {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsDimensionValue(bb: flatbuffers.ByteBuffer, obj?: DimensionValue): DimensionValue {
    return (obj || new DimensionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsDimensionValue(bb: flatbuffers.ByteBuffer, obj?: DimensionValue): DimensionValue {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new DimensionValue()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  dimType(): DimensionValueType {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readInt8(this.bb_pos + offset) : DimensionValueType.UNKNOWN;
  }

  dimValue(): bigint {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
  }

  dimParam(): string | null;
  dimParam(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  dimParam(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  static startDimensionValue(builder: flatbuffers.Builder) {
    builder.startObject(3);
  }

  static addDimType(builder: flatbuffers.Builder, dimType: DimensionValueType) {
    builder.addFieldInt8(0, dimType, DimensionValueType.UNKNOWN);
  }

  static addDimValue(builder: flatbuffers.Builder, dimValue: bigint) {
    builder.addFieldInt64(1, dimValue, BigInt('0'));
  }

  static addDimParam(builder: flatbuffers.Builder, dimParamOffset: flatbuffers.Offset) {
    builder.addFieldOffset(2, dimParamOffset, 0);
  }

  static endDimensionValue(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createDimensionValue(
    builder: flatbuffers.Builder,
    dimType: DimensionValueType,
    dimValue: bigint,
    dimParamOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    DimensionValue.startDimensionValue(builder);
    DimensionValue.addDimType(builder, dimType);
    DimensionValue.addDimValue(builder, dimValue);
    DimensionValue.addDimParam(builder, dimParamOffset);
    return DimensionValue.endDimensionValue(builder);
  }
}
