// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

/**
 * deprecated: no longer using kernel def hashes
 */
export class DeprecatedKernelCreateInfos {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): DeprecatedKernelCreateInfos {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsDeprecatedKernelCreateInfos(
    bb: flatbuffers.ByteBuffer,
    obj?: DeprecatedKernelCreateInfos,
  ): DeprecatedKernelCreateInfos {
    return (obj || new DeprecatedKernelCreateInfos()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsDeprecatedKernelCreateInfos(
    bb: flatbuffers.ByteBuffer,
    obj?: DeprecatedKernelCreateInfos,
  ): DeprecatedKernelCreateInfos {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new DeprecatedKernelCreateInfos()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  nodeIndices(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
  }

  nodeIndicesLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  nodeIndicesArray(): Uint32Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Uint32Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  kernelDefHashes(index: number): bigint | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.readUint64(this.bb!.__vector(this.bb_pos + offset) + index * 8) : BigInt(0);
  }

  kernelDefHashesLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startDeprecatedKernelCreateInfos(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addNodeIndices(builder: flatbuffers.Builder, nodeIndicesOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, nodeIndicesOffset, 0);
  }

  static createNodeIndicesVector(builder: flatbuffers.Builder, data: number[] | Uint32Array): flatbuffers.Offset;
  /**
   * @deprecated This Uint8Array overload will be removed in the future.
   */
  static createNodeIndicesVector(builder: flatbuffers.Builder, data: number[] | Uint8Array): flatbuffers.Offset;
  static createNodeIndicesVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint32Array | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt32(data[i]!);
    }
    return builder.endVector();
  }

  static startNodeIndicesVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addKernelDefHashes(builder: flatbuffers.Builder, kernelDefHashesOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, kernelDefHashesOffset, 0);
  }

  static createKernelDefHashesVector(builder: flatbuffers.Builder, data: bigint[]): flatbuffers.Offset {
    builder.startVector(8, data.length, 8);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt64(data[i]!);
    }
    return builder.endVector();
  }

  static startKernelDefHashesVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(8, numElems, 8);
  }

  static endDeprecatedKernelCreateInfos(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createDeprecatedKernelCreateInfos(
    builder: flatbuffers.Builder,
    nodeIndicesOffset: flatbuffers.Offset,
    kernelDefHashesOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    DeprecatedKernelCreateInfos.startDeprecatedKernelCreateInfos(builder);
    DeprecatedKernelCreateInfos.addNodeIndices(builder, nodeIndicesOffset);
    DeprecatedKernelCreateInfos.addKernelDefHashes(builder, kernelDefHashesOffset);
    return DeprecatedKernelCreateInfos.endDeprecatedKernelCreateInfos(builder);
  }
}
