// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { DeprecatedSessionState } from '../../onnxruntime/fbs/deprecated-session-state.js';

/**
 * deprecated: no longer using kernel def hashes
 */
export class DeprecatedSubGraphSessionState {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): DeprecatedSubGraphSessionState {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsDeprecatedSubGraphSessionState(
    bb: flatbuffers.ByteBuffer,
    obj?: DeprecatedSubGraphSessionState,
  ): DeprecatedSubGraphSessionState {
    return (obj || new DeprecatedSubGraphSessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsDeprecatedSubGraphSessionState(
    bb: flatbuffers.ByteBuffer,
    obj?: DeprecatedSubGraphSessionState,
  ): DeprecatedSubGraphSessionState {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new DeprecatedSubGraphSessionState()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  graphId(): string | null;
  graphId(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  graphId(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  sessionState(obj?: DeprecatedSessionState): DeprecatedSessionState | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? (obj || new DeprecatedSessionState()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!)
      : null;
  }

  static startDeprecatedSubGraphSessionState(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addGraphId(builder: flatbuffers.Builder, graphIdOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, graphIdOffset, 0);
  }

  static addSessionState(builder: flatbuffers.Builder, sessionStateOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, sessionStateOffset, 0);
  }

  static endDeprecatedSubGraphSessionState(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // graph_id
    return offset;
  }
}
