// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ArgTypeAndIndex } from '../../onnxruntime/fbs/arg-type-and-index.js';

export class KernelTypeStrArgsEntry {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): KernelTypeStrArgsEntry {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsKernelTypeStrArgsEntry(
    bb: flatbuffers.ByteBuffer,
    obj?: KernelTypeStrArgsEntry,
  ): KernelTypeStrArgsEntry {
    return (obj || new KernelTypeStrArgsEntry()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsKernelTypeStrArgsEntry(
    bb: flatbuffers.ByteBuffer,
    obj?: KernelTypeStrArgsEntry,
  ): KernelTypeStrArgsEntry {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new KernelTypeStrArgsEntry()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  kernelTypeStr(): string | null;
  kernelTypeStr(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  kernelTypeStr(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  args(index: number, obj?: ArgTypeAndIndex): ArgTypeAndIndex | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? (obj || new ArgTypeAndIndex()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  argsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startKernelTypeStrArgsEntry(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addKernelTypeStr(builder: flatbuffers.Builder, kernelTypeStrOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, kernelTypeStrOffset, 0);
  }

  static addArgs(builder: flatbuffers.Builder, argsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, argsOffset, 0);
  }

  static createArgsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startArgsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endKernelTypeStrArgsEntry(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 4); // kernel_type_str
    return offset;
  }

  static createKernelTypeStrArgsEntry(
    builder: flatbuffers.Builder,
    kernelTypeStrOffset: flatbuffers.Offset,
    argsOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    KernelTypeStrArgsEntry.startKernelTypeStrArgsEntry(builder);
    KernelTypeStrArgsEntry.addKernelTypeStr(builder, kernelTypeStrOffset);
    KernelTypeStrArgsEntry.addArgs(builder, argsOffset);
    return KernelTypeStrArgsEntry.endKernelTypeStrArgsEntry(builder);
  }
}
