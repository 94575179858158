// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { RuntimeOptimizationRecordContainerEntry } from '../../onnxruntime/fbs/runtime-optimization-record-container-entry.js';

export class RuntimeOptimizations {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): RuntimeOptimizations {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsRuntimeOptimizations(bb: flatbuffers.ByteBuffer, obj?: RuntimeOptimizations): RuntimeOptimizations {
    return (obj || new RuntimeOptimizations()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsRuntimeOptimizations(
    bb: flatbuffers.ByteBuffer,
    obj?: RuntimeOptimizations,
  ): RuntimeOptimizations {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new RuntimeOptimizations()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  /**
   * mapping from optimizer name to [RuntimeOptimizationRecord]
   */
  records(
    index: number,
    obj?: RuntimeOptimizationRecordContainerEntry,
  ): RuntimeOptimizationRecordContainerEntry | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new RuntimeOptimizationRecordContainerEntry()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  recordsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startRuntimeOptimizations(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addRecords(builder: flatbuffers.Builder, recordsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, recordsOffset, 0);
  }

  static createRecordsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startRecordsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endRuntimeOptimizations(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createRuntimeOptimizations(
    builder: flatbuffers.Builder,
    recordsOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    RuntimeOptimizations.startRuntimeOptimizations(builder);
    RuntimeOptimizations.addRecords(builder, recordsOffset);
    return RuntimeOptimizations.endRuntimeOptimizations(builder);
  }
}
