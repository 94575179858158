// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { TypeInfoValue } from '../../onnxruntime/fbs/type-info-value.js';

export class TypeInfo {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): TypeInfo {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsTypeInfo(bb: flatbuffers.ByteBuffer, obj?: TypeInfo): TypeInfo {
    return (obj || new TypeInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsTypeInfo(bb: flatbuffers.ByteBuffer, obj?: TypeInfo): TypeInfo {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new TypeInfo()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  denotation(): string | null;
  denotation(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  denotation(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  valueType(): TypeInfoValue {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.readUint8(this.bb_pos + offset) : TypeInfoValue.NONE;
  }

  value(obj: any): any | null {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
  }

  static startTypeInfo(builder: flatbuffers.Builder) {
    builder.startObject(3);
  }

  static addDenotation(builder: flatbuffers.Builder, denotationOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, denotationOffset, 0);
  }

  static addValueType(builder: flatbuffers.Builder, valueType: TypeInfoValue) {
    builder.addFieldInt8(1, valueType, TypeInfoValue.NONE);
  }

  static addValue(builder: flatbuffers.Builder, valueOffset: flatbuffers.Offset) {
    builder.addFieldOffset(2, valueOffset, 0);
  }

  static endTypeInfo(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createTypeInfo(
    builder: flatbuffers.Builder,
    denotationOffset: flatbuffers.Offset,
    valueType: TypeInfoValue,
    valueOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    TypeInfo.startTypeInfo(builder);
    TypeInfo.addDenotation(builder, denotationOffset);
    TypeInfo.addValueType(builder, valueType);
    TypeInfo.addValue(builder, valueOffset);
    return TypeInfo.endTypeInfo(builder);
  }
}
