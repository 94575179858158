// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { KernelTypeStrResolver } from '../../onnxruntime/fbs/kernel-type-str-resolver.js';
import { Model } from '../../onnxruntime/fbs/model.js';

export class InferenceSession {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): InferenceSession {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsInferenceSession(bb: flatbuffers.ByteBuffer, obj?: InferenceSession): InferenceSession {
    return (obj || new InferenceSession()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsInferenceSession(bb: flatbuffers.ByteBuffer, obj?: InferenceSession): InferenceSession {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new InferenceSession()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static bufferHasIdentifier(bb: flatbuffers.ByteBuffer): boolean {
    return bb.__has_identifier('ORTM');
  }

  ortVersion(): string | null;
  ortVersion(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  ortVersion(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  model(obj?: Model): Model | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? (obj || new Model()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
  }

  kernelTypeStrResolver(obj?: KernelTypeStrResolver): KernelTypeStrResolver | null {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset
      ? (obj || new KernelTypeStrResolver()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!)
      : null;
  }

  static startInferenceSession(builder: flatbuffers.Builder) {
    builder.startObject(4);
  }

  static addOrtVersion(builder: flatbuffers.Builder, ortVersionOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, ortVersionOffset, 0);
  }

  static addModel(builder: flatbuffers.Builder, modelOffset: flatbuffers.Offset) {
    builder.addFieldOffset(1, modelOffset, 0);
  }

  static addKernelTypeStrResolver(builder: flatbuffers.Builder, kernelTypeStrResolverOffset: flatbuffers.Offset) {
    builder.addFieldOffset(3, kernelTypeStrResolverOffset, 0);
  }

  static endInferenceSession(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static finishInferenceSessionBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Offset) {
    builder.finish(offset, 'ORTM');
  }

  static finishSizePrefixedInferenceSessionBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Offset) {
    builder.finish(offset, 'ORTM', true);
  }
}
