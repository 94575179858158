// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class OperatorSetId {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): OperatorSetId {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsOperatorSetId(bb: flatbuffers.ByteBuffer, obj?: OperatorSetId): OperatorSetId {
    return (obj || new OperatorSetId()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsOperatorSetId(bb: flatbuffers.ByteBuffer, obj?: OperatorSetId): OperatorSetId {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new OperatorSetId()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  domain(): string | null;
  domain(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  domain(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
  }

  version(): bigint {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
  }

  static startOperatorSetId(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addDomain(builder: flatbuffers.Builder, domainOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, domainOffset, 0);
  }

  static addVersion(builder: flatbuffers.Builder, version: bigint) {
    builder.addFieldInt64(1, version, BigInt('0'));
  }

  static endOperatorSetId(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createOperatorSetId(
    builder: flatbuffers.Builder,
    domainOffset: flatbuffers.Offset,
    version: bigint,
  ): flatbuffers.Offset {
    OperatorSetId.startOperatorSetId(builder);
    OperatorSetId.addDomain(builder, domainOffset);
    OperatorSetId.addVersion(builder, version);
    return OperatorSetId.endOperatorSetId(builder);
  }
}
