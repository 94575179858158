// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Dimension } from '../../onnxruntime/fbs/dimension.js';

export class Shape {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): Shape {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsShape(bb: flatbuffers.ByteBuffer, obj?: Shape): Shape {
    return (obj || new Shape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsShape(bb: flatbuffers.ByteBuffer, obj?: Shape): Shape {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new Shape()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  dim(index: number, obj?: Dimension): Dimension | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new Dimension()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  dimLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startShape(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addDim(builder: flatbuffers.Builder, dimOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, dimOffset, 0);
  }

  static createDimVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startDimVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endShape(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createShape(builder: flatbuffers.Builder, dimOffset: flatbuffers.Offset): flatbuffers.Offset {
    Shape.startShape(builder);
    Shape.addDim(builder, dimOffset);
    return Shape.endShape(builder);
  }
}
