// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { OpIdKernelTypeStrArgsEntry } from '../../onnxruntime/fbs/op-id-kernel-type-str-args-entry.js';

export class KernelTypeStrResolver {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): KernelTypeStrResolver {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsKernelTypeStrResolver(
    bb: flatbuffers.ByteBuffer,
    obj?: KernelTypeStrResolver,
  ): KernelTypeStrResolver {
    return (obj || new KernelTypeStrResolver()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  static getSizePrefixedRootAsKernelTypeStrResolver(
    bb: flatbuffers.ByteBuffer,
    obj?: KernelTypeStrResolver,
  ): KernelTypeStrResolver {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new KernelTypeStrResolver()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
  }

  opKernelTypeStrArgs(index: number, obj?: OpIdKernelTypeStrArgsEntry): OpIdKernelTypeStrArgsEntry | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? (obj || new OpIdKernelTypeStrArgsEntry()).__init(
          this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4),
          this.bb!,
        )
      : null;
  }

  opKernelTypeStrArgsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startKernelTypeStrResolver(builder: flatbuffers.Builder) {
    builder.startObject(1);
  }

  static addOpKernelTypeStrArgs(builder: flatbuffers.Builder, opKernelTypeStrArgsOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, opKernelTypeStrArgsOffset, 0);
  }

  static createOpKernelTypeStrArgsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startOpKernelTypeStrArgsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endKernelTypeStrResolver(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createKernelTypeStrResolver(
    builder: flatbuffers.Builder,
    opKernelTypeStrArgsOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    KernelTypeStrResolver.startKernelTypeStrResolver(builder);
    KernelTypeStrResolver.addOpKernelTypeStrArgs(builder, opKernelTypeStrArgsOffset);
    return KernelTypeStrResolver.endKernelTypeStrResolver(builder);
  }
}
